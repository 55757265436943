<template>
  <v-container>
    <v-text-field v-model="localValue" @keyup="charCount()"> </v-text-field>
    <span>Current: {{ totalChar }} characters</span>
  </v-container>
</template>

<script>
export default {
  name: "TextField",
  props: {
    value: String,
    myDefault: String,
  },
  data: () => ({
    localValue: "",
    totalChar: 0,
  }),
  created() {
    this.localValue = this.myDefault
    this.totalChar = this.myDefault.length
    this.$watch("localValue", (value) => {
      this.$emit("input", value)
    })
  },
  methods: {
    charCount() {
      this.totalChar = this.localValue.length
    },
  },
}
</script>
