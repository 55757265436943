<template>
  <v-container height="50%">
    <v-layout row wrap justify-center my-5>
      <v-card elevation="3" max-width="100%">
        <v-card-title><h2>Matchstick</h2></v-card-title>
        <v-card-text>
          <v-row class="ml-5">
            <v-radio-group v-model="fileType" row>
              <h4>File type: &nbsp;&nbsp;&nbsp;</h4>
              <!-- AP Files come from Accounts Payable and can be broken into NEC and/or MISC file types -->
              <v-radio label="AP" value="AP"></v-radio>
              <!-- MP files come from the Student Union and are Meal Plan information -->
              <v-radio label="MP" value="MP"></v-radio>
            </v-radio-group>
          </v-row>

          <v-row class="ml-5">
            <v-radio-group v-model="testFile" row>
              <h4>Is this a test file? &nbsp;&nbsp;&nbsp;</h4>
              <v-radio label="Yes" value="Yes"></v-radio>
              <v-radio label="No" value="No"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row class="ml-5">
            <v-radio-group v-model="priorYear" row>
              <h4>Is this for a prior year? &nbsp;&nbsp;&nbsp;</h4>
              <v-radio label="Yes" value="Yes"></v-radio>
              <v-radio label="No" value="No"></v-radio>
            </v-radio-group>
          </v-row>

          <v-row class="ml-5 mr-5">
            <v-col>
              <v-file-input
                v-model="csvFile"
                accept=".csv"
                label="Choose a CSV file"
                outlined
                @click="turnOffDownload"
              >
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right">
              <v-btn
                id="upload"
                color="#1e5288"
                class="white--text"
                @click="checkFile"
                >Upload</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="apDownloadable">
            <a :href="miscUrl" download=""> Download MISC(.txt)</a>
          </v-row>
          <v-row v-if="apDownloadable">
            <a :href="miscReportUrl" download="">
              Download MISC Report(.xlsx)</a
            >
          </v-row>
          <v-row v-if="apDownloadable">
            <a :href="necUrl" download=""> Download NEC(.txt)</a>
          </v-row>
          <v-row v-if="apDownloadable">
            <a :href="necReportUrl" download=""> Download NEC Report(.xlsx)</a>
          </v-row>
          <v-row v-if="mpDownloadable">
            <a :href="kUrl" download=""> Download K(.txt)</a>
          </v-row>
          <v-row v-if="mpDownloadable">
            <a :href="kReportUrl" download=""> Download K Report(.xlsx)</a>
          </v-row>
          <v-row v-if="overflowDownloadable">
            <a :href="overflowReportUrl" download="">
              Download Overflow Report(.txt)</a
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-layout>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <template #activator="{ on, attrs }">
          <v-btn
            v-if="isOverflow"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Open Overflow List
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h4">Overflow List</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container v-for="(i, ind) in overflow" :key="i['data']">
                <v-row>
                  <p class="font-weight-medium">{{ i["Data"] }}</p>
                </v-row>
                <v-row>
                  Overflow Length:
                  <strong class="red--text text--lighten-1">{{
                    i["Current Length"]
                  }}</strong>
                  &nbsp;Max Length: <strong> {{ i["Max Length"] }} </strong>
                </v-row>
                <OverflowInput
                  v-model="overflowInputs[ind]"
                  :my-default="i['Current Value']"
                />
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="overflow_fix">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loading" hide-overlay persistent width="25%">
        <v-card color="primary" height="10vh" class="pt-5" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-5"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import OverflowInput from "@/components/OverflowInput.vue"

export default {
  name: "App",
  components: {
    OverflowInput,
  },
  data: () => ({
    apCorrectedInd: null,
    apMap: {},
    csvFile: null,
    downloadable: false,
    fileType: null,
    mpCorrectedInd: null,
    mpMap: {},
    priorYear: null,
    priorYearInd: null,
    testFile: null,
    testFileInd: null,
    dialog: false,
    overflow: [],
    overflowForm: {},
    isOverflow: false,
    apDownloadable: false,
    mpDownloadable: false,
    overflowDownloadable: false,
    miscUrl: "",
    kUrl: "",
    necUrl: "",
    miscReportUrl: "",
    necReportUrl: "",
    kReportUrl: "",
    overflowReportUrl: "",
    overflowInputs: [],
    loading: false,
  }),
  computed: {
    ap_download: function () {
      if (this.fileType == "AP" && this.downloadable) {
        return true
      }
      return false
    },
    mp_download: function () {
      if (this.fileType == "MP" && this.downloadable) {
        return true
      }
      return false
    },
  },
  beforeMount() {
    this.getIrsFields()
  },
  methods: {
    // assessCorrectionFileType alerts the user if a file is a correction file and prompts the user's approval to continue processing file. If the file is not a correction file, the file will be processed.
    async assessCorrectionFileType() {
      let cfi = await this.getCorrectedFileIndicator()
      this.setFormData()
      if (cfi == 1) {
        this.$store.dispatch("changeToCorrectedFileAction", this)
        this.$alert
          .fire({
            title: "WARNING!",
            text:
              this.csvFile.name +
              " is a correction file. Would you like to proceed?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#f19e1f",
            cancelButtonColor: "#D9D9D9",
            confirmButtonText: "Yes",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.uploadFile()
            } else {
              return false
            }
          })
      } else {
        this.$store.dispatch("changeToNotCorrectedFileAction", this)
        this.uploadFile()
      }
    },
    //checkFile runs basic validations to ensure a .csv file has been selected for upload and that all form options have been selected
    turnOffDownload() {
      this.apDownloadable = false
      this.mpDownloadable = false
      this.overflowDownloadable = false
    },
    downloadMisc() {
      this.$api.post("/download/misc", this.csvFile).then((response) => {})
    },
    checkFile() {
      if (!this.csvFile) {
        this.$alert.fire({
          text: "No file chosen! Please upload a file.",
          icon: "error",
        })
      } else if (this.csvFile.type != "text/csv") {
        this.$alert.fire({
          text:
            this.csvFile.type +
            " is not an accepted file type. Please enter a .csv file.",
          icon: "error",
        })
      } else if (this.fileType == null) {
        this.$alert.fire({
          text: "No file type selected. Please choose a file type.",
          icon: "error",
        })
      } else if (this.testFile == null) {
        this.$alert.fire({
          text: "Test file not specified. Please choose if this is a test file.",
          icon: "error",
        })
      } else if (this.priorYear == null) {
        this.$alert.fire({
          text: "Prior year not specified. Please choose if this is for a prior year.",
          icon: "error",
        })
      } else {
        this.assessCorrectionFileType()
      }
    },
    //getCorrectedFileIndicator parses the data from the uploaded file and assesses whether the file is a corrected file. This function returns an indicator of 1 for a corrected file or 0 for a not corrected file.
    async getCorrectedFileIndicator() {
      let correctedFileIndicator = 0
      const parseFile = (rawFile) => {
        return new Promise((resolve) => {
          this.$papa.parse(rawFile, {
            header: true,
            complete: (results) => {
              resolve(results.data)
            },
          })
        })
      }
      let parsedData = await parseFile(this.csvFile)

      this.$store.dispatch("uploadDataActions", parsedData)

      let cri

      if (this.fileType === "AP") {
        cri = this.apCorrectedInd
      } else {
        cri = this.mpCorrectedInd
      }

      var trim_keys = []
      var keys = Object.keys(parsedData[0])

      keys.forEach(function (element) {
        if (element.startsWith(cri.trim())) {
          cri = element
        }
      })

      for (let i = 0; i < parsedData.length - 1; i++) {
        if (typeof parsedData[i][cri] != "undefined") {
          if (parsedData[i][cri].trim() != "") {
            correctedFileIndicator = 1
            return correctedFileIndicator
          }
        }
      }
      return correctedFileIndicator
    },
    //getIrsFields gets data from the AP and MP model that is a list of dictionaries. Data[0] contains the dictionary of mappings for AP files while data[1] contains the dictionary of mappings for MP files. From these dictionaries, the user mapped column containing data for corrected return information is contained in a variable.
    getIrsFields() {
      this.$api.get("/revcolmap").then((response) => {
        let data = JSON.parse(response.data)
        this.apMap = data[0]
        this.mpMap = data[1]
        this.apCorrectedInd = this.apMap["CorrectedReturnIndicator"]
        this.mpCorrectedInd = this.mpMap["CorrectedReturnIndicator"]
      })
    },
    //setFormData translates the radio button input for testFile and priorYear into data to send back to the server
    setFormData() {
      if (this.testFile == "Yes") {
        this.testFileInd = "T"
      } else {
        this.testFileInd = " "
      }

      if (this.priorYear == "Yes") {
        this.priorYearInd = "P"
      } else {
        this.priorYearInd = " "
      }
    },
    //uploadFile assembles the formData to post back to the server to generate the downloadable file
    uploadFile() {
      this.loading = true
      this.miscUrl = "api/download/misc/" + this.csvFile.name
      this.necUrl = "api/download/nec/" + this.csvFile.name
      this.kUrl = "/api/download/k/" + this.csvFile.name
      this.miscReportUrl = "api/download/misc_report/" + this.csvFile.name
      this.necReportUrl = "api/download/nec_report/" + this.csvFile.name
      this.kReportUrl = "/api/download/k_report/" + this.csvFile.name

      let formData = new FormData()
      formData.append("file", this.csvFile)
      formData.append("type", this.fileType)
      formData.append("prior", this.priorYearInd) // P for prior or blank for not)
      formData.append("test", this.testFileInd) // T for test or blank for not

      this.$api
        .post("/fire2", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loading = false
          if (response.data == "") {
            if (this.fileType == "AP") {
              this.apDownloadable = true
            } else {
              this.mpDownloadable = true
            }
          } else {
            this.dialog = true
            this.overflow = response.data
            this.isOverflow = true
          }
        })
    },
    async overflow_fix() {
      this.overflowReportUrl =
        "/api/download/overflow_report/" + this.csvFile.name
      let formData = new FormData()
      let indicate = 0
      var updateValue = await this.updateValue()
      for (let i of this.overflow) {
        if (i["Update Value"] == undefined) {
          this.$alert.fire({
            icon: "error",
            title: "Oops...",
            text: "Please edit all overflow fields!",
          })
          indicate = 1
          break
        }
        if (i["Update Value"].length > i["Max Length"]) {
          this.$alert.fire({
            icon: "error",
            title: "Oops...",
            text: "Some fields are still overflow",
          })
          indicate = 1
          break
        }
      }
      if (indicate == 0) {
        this.dialog = false
        formData.append("file", this.csvFile.name)
        formData.append("type", this.fileType)
        formData.append("prior", this.priorYearInd) // P for prior or blank for not)
        formData.append("test", this.testFileInd) // T for test or blank for not
        formData.append("overflow", JSON.stringify(this.overflow))
        this.loading = true
        this.$api
          .post("/overflow", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.loading = false
            this.overflowDownloadable = true
            if (this.fileType == "AP") {
              this.apDownloadable = true
            } else {
              this.mpDownloadable = true
            }
            this.overflowInputs = []
            this.overflow = []
          })
      }
    },
    async updateValue() {
      let index = 0
      return new Promise((resolve) => {
        for (let i of this.overflow) {
          i["Update Value"] = this.overflowInputs[index]
          index += 1
        }
        resolve("done")
      })
    },
  },
}
</script>

<style></style>
